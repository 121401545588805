@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html,
  body {
    overscroll-behavior-x: none;
  }
  body {
    background: var(--background);
    color: var(--foreground);
  }
  :root {
    --background: #ffffff;
    --foreground: #040301;
    --primary: #f1c115;
    --secondary: #45454a;
    --radius: 10px;
    --shadow-low: 0px 1px 8px 0px var(--shadow-weak);
    --shadow-medium: 0px 4px 20px 0px var(--shadow-weak);
    --shadow-high: 0px 1px 8px 0px var(--shadow), 0px 16px 40px 0px var(--shadow-strong);
    --disabled: #e8e1d9;
    --overlay: rgba(38, 38, 38, 0.75);
    --container: #fff7f0;
    --container-high: #f7f1e8;
    --inverse: #282520;
    --on-inverse: #fff7f0;
    --subtle: #7b766f;
    --on-disabled: #bfb9b0;
    --stroke: #dbd4cc;
    --stroke-strong: #bfb9b0;
    --stroke-subtle: #e8e1d9;
    --stroke-disabled: #f7f1e8;
    --stroke-inverse: #969088;
    --primary-accent: #f7f7e8;
    --primary-container: #ffefcc;
    --stroke-primary: #b38c00;
    --on-primary: #040301;
    --on-primary-container: #3d2f00;
    --primary-strong: #f1c113;
    --primary-hover: #deb10d;
    --on-primary-hover: #040301;
    --primary-pressed: #d2a70d;
    --on-primary-pressed: #040301;
    --neutral-accent: #fff7f0;
    --neutral-container: #f7f1e8;
    --neutral-stroke: #afa9a1;
    --neutral: #615d56;
    --on-neutral: #ffffff;
    --on-neutral-container: #282520;
    --neutral-hover: #0b0805;
    --on-neutral-hover: #f7f1e8;
    --neutral-pressed: #000000;
    --on-neutral-pressed: #ffffff;
    --success-accent: #e8fff0;
    --success-container: #bdffdd;
    --stroke-success: #00a575;
    --success: #006c4c;
    --on-success: #ffffff;
    --on-success-container: #003826;
    --success-strong: #25c28d;
    --success-hover: #005138;
    --on-success-hover: #ffffff;
    --success-pressed: #00452f;
    --on-success-pressed: #ffffff;
    --warning-accent: #fff9ea;
    --warning-container: #fff2ab;
    --stroke-warning: #a39100;
    --warning: #6a5f00;
    --warning-strong: #c0ac00;
    --on-warning: #ffffff;
    --on-warning-container: #373100;
    --warning-status: #c0ac00;
    --warning-hover: #504700;
    --on-warning-hover: #ffffff;
    --warning-pressed: #443c00;
    --on-warning-pressed: #ffffff;
    --danger-accent: #fff6f5;
    --danger-container: #ffeeeb;
    --stroke-danger: #ff5447;
    --danger: #bb1b1b;
    --on-danger: #ffffff;
    --on-danger-container: #6b0005;
    --danger-strong: #ff5447;
    --danger-hover: #94000a;
    --on-danger-hover: #ffffff;
    --danger-pressed: #800006;
    --on-danger-pressed: #ffffff;
    --info-accent: #fbfaff;
    --info-container: #ebeeff;
    --stroke-info: #528bff;
    --info: #005ac7;
    --on-info: #ffffff;
    --on-info-container: #002d6b;
    --info-strong: #528bff;
    --info-hover: #004299;
    --on-info-hover: #ffffff;
    --info-pressed: #003780;
    --on-info-pressed: #ffffff;
    --shadow-weak: rgba(0, 0, 0, 0.10000000149011612);
    --shadow: rgba(17, 17, 17, 0.10000000149011612);
    --shadow-strong: rgba(17, 17, 17, 0.15000000596046448);
    --radius-xs: 6px;
    --radius-sm: 8px;
    --radius-md: 12px;
    --radius-lg: 16px;
  }
  .dark {
    --background: #000000;
    --foreground: #fffaff;
    --primary: #f1c115;
    --secondary: #adacaf;
    --disabled: #1d1b16;
    --overlay: rgba(11, 7, 7, 0.5);
    --container: #282520;
    --container-high: #322f29;
    --inverse: #dbd4cc;
    --on-inverse: #040301;
    --subtle: #7b766f;
    --on-disabled: #4a4640;
    --stroke: #3f3c36;
    --stroke-strong: #4a4640;
    --stroke-subtle: #322f29;
    --stroke-disabled: #1d1b16;
    --stroke-inverse: #4a4640;
    --primary-accent: #1a1200;
    --primary-container: #241a00;
    --stroke-primary: #755c00;
    --on-primary: #040301;
    --on-primary-container: #f1c113;
    --primary-strong: #755c00;
    --primary-hover: #deb10d;
    --on-primary-hover: #040301;
    --primary-pressed: #d2a70d;
    --on-primary-pressed: #040301;
    --neutral-accent: #13110c;
    --neutral-container: #282520;
    --neutral-stroke: #615d56;
    --neutral: #ffff;
    --on-neutral: #7b766f;
    --on-neutral-container: #dbd4cc;
    --neutral-hover: #e8e1d9;
    --on-neutral-hover: #0b0805;
    --neutral-pressed: #ccc6bd;
    --on-neutral-pressed: #000000;
    --success-accent: #00150c;
    --success-container: #002c1d;
    --stroke-success: #006c4c;
    --success: #00a575;
    --on-success: #ffffff;
    --on-success-container: #4edea7;
    --success-strong: #005138;
    --success-hover: #005138;
    --on-success-hover: #ffffff;
    --success-pressed: #00452f;
    --on-success-pressed: #ffffff;
    --warning-accent: #141100;
    --warning-container: #2c2600;
    --stroke-warning: #6a5f00;
    --warning: #867800;
    --warning-strong: #6a5f00;
    --on-warning: #ffffff;
    --on-warning-container: #dec800;
    --warning-status: #504700;
    --warning-hover: #504700;
    --on-warning-hover: #ffffff;
    --warning-pressed: #443c00;
    --on-warning-pressed: #ffffff;
    --danger-accent: #2e0001;
    --danger-container: #520003;
    --stroke-danger: #bb1b1b;
    --danger: #ff5447;
    --on-danger: #ffffff;
    --on-danger-container: #ffb6ad;
    --danger-strong: #bb1b1b;
    --danger-hover: #94000a;
    --on-danger-hover: #ffffff;
    --danger-pressed: #800006;
    --on-danger-pressed: #ffffff;
    --info-accent: #000f2e;
    --info-container: #002357;
    --stroke-info: #005ac7;
    --info: #528bff;
    --on-info: #ffffff;
    --on-info-container: #fbfaff;
    --info-strong: #005ac7;
    --info-hover: #004299;
    --on-info-hover: #ffffff;
    --info-pressed: #003780;
    --on-info-pressed: #ffffff;
    --shadow-weak: rgba(255, 255, 255, 0);
    --shadow: rgba(238, 238, 238, 0);
    --shadow-strong: rgba(238, 238, 238, 0);
  }
}

html {
  font-family: var(--font-inter), system-ui, sans-serif;
}

@layer base {
  .ion-shadow {
    box-shadow: 0 0 4px #f1c115, 0 0 12px rgba(241, 193, 21, 0.7), 0 0 20px rgba(241, 193, 21, 0.4);
  }
  .ion-title {
    @apply dark:from-primary w-fit bg-gradient-to-r from-yellow-600 to-yellow-500 bg-clip-text text-xl font-bold text-transparent dark:to-yellow-600;
  }
  * {
    @apply border-stroke font-sans box-border;

    scrollbar-width: thin;
    scrollbar-color: var(--container-high) transparent;
  }
  body {
    @apply bg-background text-foreground;

    font-feature-settings: 'rlig' 1, 'calt' 1;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply font-headers;
    @apply tracking-wide;
  }
  body {
    @apply font-sans;
  }
}

.codestyle {
  span:empty {
    margin-bottom: 4px;
    margin-top: 4px;
  }
}

span .line {
  margin-top: 16px;
}

@layer components {
  .primary-focus {
    outline: none;
    box-shadow: 0px 0px 0px 3px var(--primary-container);
  }

  .neutral-focus {
    outline: none;
    box-shadow: 0px 0px 0px 3px var(--neutral-container);
  }

  .danger-focus {
    outline: none;
    box-shadow: 0px 0px 0px 3px var(--danger-container);
  }
}

.linenumber {
  font-style: normal !important;
  font-size: 12px;
}

.markdown {
  width: 100%;
  line-height: 1.6;
  color: var(--foreground);

  > *:not(:last-child) {
    margin-block-end: 16px;
  }

  :global(.artifact) {
    margin: 1.5em 0;
  }

  h1 {
    font-size: 2em;
    border-bottom: 1px solid var(--stroke);
    padding-bottom: 0.3em;
  }

  h2 {
    font-size: 1.5em;
    border-bottom: 1px solid var(--stroke);
    padding-bottom: 0.3em;
  }

  h3 {
    font-size: 1.25em;
  }

  h4 {
    font-size: 1em;
  }

  h5 {
    font-size: 0.875em;
  }

  h6 {
    font-size: 0.85em;
    color: #6a737d;
  }

  p {
    white-space: pre-wrap;
    color: var(--foreground);
    &:not(:last-of-type) {
      margin-block-start: 0;
      margin-block-end: 16px;
    }
  }

  a {
    color: var(--info);
    text-decoration: none;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  :not(pre) > code {
    font-size: 13px;
  }

  pre {
    padding: 20px 16px;
    border-radius: 6px;
  }

  pre:has(> code) {
    font-size: 13px;
    background: transparent;
    overflow-x: auto;
    min-width: 0;
  }

  blockquote {
    margin: 0;
    padding: 0 1em;
    color: var(--subtle);
    border-left: 0.25em solid var(--stroke);
  }

  img {
    max-width: 100%;
    box-sizing: border-box;
  }

  hr {
    height: 0.25em;
    padding: 0;
    margin: 24px 0;
    background-color: var(--stroke);
    border: 0;
  }

  table {
    border-collapse: collapse;
    width: 100%;
    margin-block-end: 16px;

    :is(th, td) {
      padding: 6px 13px;
      border: 1px solid #dfe2e5;
    }

    tr:nth-child(2n) {
      background-color: #f6f8fa;
    }
  }
}
code {
  counter-reset: step;
  counter-increment: step 0;
}

code .line::before {
  content: counter(step);
  counter-increment: step;
  width: 1rem;
  margin-right: 0.5rem;
  display: inline-block;
  text-align: center;
  color: rgba(115, 138, 148, 0.4);
}
